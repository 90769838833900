.loader_msg {
    position: fixed;
    background-color: rgba(204, 204, 204, 0.897);
    border-radius: 5px;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    margin-top: 105px;
    text-align: center;
    color: #6d50ff;
}

.newLoading {
    position: fixed;
    z-index: 99999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Transparent Overlay */
.newLoading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.newLoading:not(:required) {
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.newLoading:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(144, 124, 245, 1) 1.5em 0 0 0,
        rgba(144, 124, 245, 1) 1.1em 1.1em 0 0, rgba(144, 124, 245, 1) 0 1.5em 0 0,
        rgba(144, 124, 245, 1) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
        rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(144, 124, 245, 1) 0 -1.5em 0 0,
        rgba(144, 124, 245, 1) 1.1em -1.1em 0 0;
    box-shadow: rgba(144, 124, 245, 1) 1.5em 0 0 0,
        rgba(144, 124, 245, 1) 1.1em 1.1em 0 0, rgba(144, 124, 245, 1) 0 1.5em 0 0,
        rgba(144, 124, 245, 1) -1.1em 1.1em 0 0, rgba(144, 124, 245, 1) -1.5em 0 0 0,
        rgba(144, 124, 245, 1) -1.1em -1.1em 0 0, rgba(144, 124, 245, 1) 0 -1.5em 0 0,
        rgba(144, 124, 245, 1) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}